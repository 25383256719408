<template>
  <v-list-item
    v-if="item"
    :to="{ name: 'Product', params: { slug: item.product.slug } }"
    class="list-item px-1 px-sm-0 py-1"
    flat
  >
    <v-row no-gutters class="align-center">
      <v-col cols="12" sm="5" lg="6">
        <v-row no-gutters align="center">
          <v-list-item-avatar width="80" height="80" rounded="0">
            <img
              :src="item.product.mediaURL"
              :alt="item.product.name"
              onerror="this.onerror=null;this.src='/no-icon.png'"
            />
          </v-list-item-avatar>

          <v-list-item-content class="default--text pa-0 pa-sm-2">
            <div class="font-weight-bold text-body-2">
              {{ item.product.name }}
            </div>
            <div class="text-uppercase text-body-2">
              {{ item.product.shortDescr }}
            </div>
          </v-list-item-content>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="7"
        lg="6"
        class="d-flex w-100 justify-space-between justify-sm-end align-center mb-5 mb-sm-0 wrap-xs-column"
      >
        <ProductPrice :product="item.product" :isList="true" />

        <template v-if="isActive">
          <div class="d-flex">
            <div
              class="qty-wrap rounded-md"
              :class="{ 'not-empty': quantity > 0 }"
              transition="fab-transition"
            >
              <v-btn
                tile
                aria-label="Diminuisci quantità"
                depressed
                color="grey lighten-2"
                small
                class="minus"
                @click.stop.prevent="minus"
                @mousedown.stop
                v-ripple
              >
                <v-icon>$minus</v-icon>
              </v-btn>
              <div class="val-cnt">
                <span class="val">{{ quantity }} {{ unit }}</span>
                <span class="small ml-1">{{ quantityPerUnit }}</span>
              </div>
              <v-btn
                aria-label="Diminuisci quantità"
                depressed
                color="grey lighten-2"
                small
                class="plus"
                @click.stop.prevent="plus"
                @mousedown.stop
                v-ripple
              >
                <v-icon>$plus</v-icon>
              </v-btn>
            </div>
            <v-btn
              color="primary"
              class="add-to-cart"
              elevation="0"
              height="45"
              @click.stop.prevent="addToCart(quantity)"
            >
              <v-icon>$cart</v-icon></v-btn
            >
          </div>
        </template>
        <div v-else class="not-available">
          {{ $t("product.notAvailable") }}
        </div>

        <v-btn small icon color="primary" v-on:click.prevent="removeItem()">
          <v-icon>$delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<style lang="scss">
.list-item {
  border-bottom: 1px solid var(--v-grey-lighten1);
  .price_block {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      justify-content: left !important;
    }
  }
  .product-price {
    min-width: 140px;
  }
  .cur-price {
    font-size: 16px;
    padding: 10px;
    text-align: center;
    min-width: 105px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-width: auto;
    }
  }
  .price-um,
  .old-price {
    text-align: center;
  }
  .gross-total {
    font-size: 18px;
    font-weight: 700;
  }
  .qty-wrap {
    margin-left: 8px;
  }
  .add-to-cart {
    margin: 0 10px 0 5px;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      color: $white;
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin: 0 5px 0 3px;
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .promo_discount {
      flex-direction: column !important;
    }
  }
  .promo_discount .old-price {
    margin-bottom: 8px !important;
    min-width: 105px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .qty-wrap {
      margin-left: 4px;
    }
  }
  @media (max-width: 380px) {
    .wrap-xs-column {
      flex-wrap: wrap;
    }
    .product-price {
      margin-bottom: 8px;
    }
    .qty-wrap {
      margin-left: 0px;
    }
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";

import CatalogService from "~/service/catalogService";
import CartService from "~/service/cartService";
import { mapActions } from "vuex";

export default {
  name: "ListItem",
  components: { ProductPrice },
  props: ["item", "isActive"],
  // mixins: [productMixin],

  computed: {
    quantity() {
      // let item = this.item;
      if (this.item === null || this.item.product === null) return 0;
      if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
        //Handling floating point decimals issues
        var val = (this.item.weight * 100).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 100;
        return val;
      } else {
        return this.item.quantity;
      }
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.item.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(
                this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
              )
          );
          var singleUnitUm = this.item.product.productInfos
            .WEIGHT_SELLING_SINGLE_UNIT_UM
            ? this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.item.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit =
            quantity *
            parseFloat(this.item.product.productInfos.WEIGHT_SELLING);
          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.item.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.item.product.productInfos) {
        if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.item.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.item.product.productInfos.UNIT_SELLING != null
            ? this.item.product.productInfos.UNIT_SELLING
            : "pz";
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    removeItem() {
      this.$emit("removeItem", {
        itemId: this.item.itemId,
        product: this.item.product
      });
    },
    plus() {
      const newQuantity = CartService.plus(this.item.product, this.quantity);

      this.$emit("update", { newQuantity, item: this.item });
    },
    async minus() {
      const newQuantity = CartService.minus(this.item.product, this.quantity);

      let res = true;
      if (newQuantity <= 0) {
        res = await this.$dialog.confirm({
          text: global.vm.$t("message.removeItemFromList")
        });
      }
      if (res) this.$emit("update", { newQuantity, item: this.item });
    },
    addToCart() {
      this.addProduct({ product: this.item.product, quantity: this.quantity });
    },
    hasPromo(item) {
      return (
        item.product.priceStandardDisplay &&
        item.unitPrice !== item.product.priceStandardDisplay
      );
    }
  }
};
</script>
